import { AppRegistry } from 'react-native';
import App from './App';
import appConfig from './app.json';
import { unstable_createRoot as createRoot } from 'react-dom';

AppRegistry.registerComponent(appConfig.name, () => App);

AppRegistry.runApplication(appConfig.name, {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
});