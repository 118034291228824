// src/hooks/useArticles.ts
import {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import axios, {AxiosResponse} from 'axios';
import {Platform} from 'react-native';
import debounce from 'lodash/debounce';

const API_URL = 'api/articles/';

type Article = {
  id: number;
  title: string;
  digest: string;
  text: string;
  source: {name: string};
  topics: {slug: string; label: string}[];
  tags: {slug: string; label: string}[];
  publication_datetime: string;
  external_link_code: string;
};

type ApiResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Article[];
};

const useArticles = (unselectedTopics: number[] | null) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const isFetchingMoreRef = useRef(false);
  const fetchedUrlsRef = useRef<Set<string>>(new Set());

  // Memoize the params to prevent unnecessary re-renders
  const articlesParams = useMemo(() => {
    if (unselectedTopics === null) {
      return null;
    }
    const params: any = {};
    if (unselectedTopics.length > 0) {
      params.exclude_topics = unselectedTopics.join(',');
    }
    return params;
  }, [unselectedTopics]);

  const fetchArticles = useCallback(
    async (url: string) => {
      if (!url || articlesParams === null) return;

      setLoading(true);
      isFetchingMoreRef.current = true;

      try {
        let response: AxiosResponse<ApiResponse>;
        if (url === API_URL) {
          // Initial fetch, include params
          response = await axios.get<ApiResponse>(url, {
            params: articlesParams,
          });
        } else {
          // Next page fetch, do not include params to avoid duplication
          response = await axios.get<ApiResponse>(url);
        }

        setArticles(prevArticles => {
          if (url === API_URL) {
            // If initial load or topics changed, replace articles
            return response.data.results;
          } else {
            // For pagination, append new articles
            const newArticles = response.data.results.filter(
              (article: Article) =>
                !prevArticles.some(
                  prevArticle => prevArticle.id === article.id,
                ),
            );
            return [...prevArticles, ...newArticles];
          }
        });

        setNextUrl(response.data.next);
        setHasMore(!!response.data.next);
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setLoading(false);
        isFetchingMoreRef.current = false;
        setIsFetchingMore(false);
      }
    },
    [articlesParams],
  );

  // Fetch articles when articlesParams change and are defined
  useEffect(() => {
    if (articlesParams !== null) {
      // Reset pagination state
      setArticles([]);
      setNextUrl(API_URL);
      fetchedUrlsRef.current.clear();
      fetchArticles(API_URL);
    }
  }, [fetchArticles, articlesParams]);

  const fetchMoreArticles = useCallback(async () => {
    if (
      !nextUrl ||
      isFetchingMoreRef.current ||
      !hasMore ||
      fetchedUrlsRef.current.has(nextUrl)
    ) {
      return;
    }

    isFetchingMoreRef.current = true;
    setIsFetchingMore(true);
    fetchedUrlsRef.current.add(nextUrl);

    try {
      await fetchArticles(nextUrl);
    } catch (error) {
      console.error('Error fetching more articles:', error);
    }
  }, [nextUrl, hasMore, fetchArticles]);

  // Web-specific scroll handling
  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleWindowScroll = debounce(() => {
        const scrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollTop + windowHeight >= documentHeight - 100) {
          fetchMoreArticles();
        }
      }, 200);

      window.addEventListener('scroll', handleWindowScroll);
      return () => {
        window.removeEventListener('scroll', handleWindowScroll);
      };
    }
  }, [fetchMoreArticles]);

  return {
    articles,
    loading,
    isFetchingMore,
    fetchMoreArticles,
  };
};

export default useArticles;
