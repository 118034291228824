// src/components/ArticleItem.tsx
import React, {useState, useCallback} from 'react';
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  useColorScheme,
  LayoutAnimation,
  Platform,
  UIManager,
  Modal,
} from 'react-native';
import {formatDateTime} from '../utils/dateUtils';
import ReactGA from 'react-ga4';

// Import WebView for mobile platforms
import {WebView} from 'react-native-webview';

type Article = {
  id: number;
  title: string;
  digest: string;
  text: string;
  source: {name: string};
  topics: {slug: string; label: string}[];
  tags: {slug: string; label: string}[];
  publication_datetime: string;
  external_link_code: string;
};

type Props = {
  article: Article;
};

const ArticleItem: React.FC<Props> = ({article}) => {
  const [expanded, setExpanded] = useState(false);
  const [webviewVisible, setWebviewVisible] = useState(false); // State to control WebView visibility
  const isDarkMode = useColorScheme() === 'dark';

  // Enable LayoutAnimation on Android
  if (
    Platform.OS === 'android' &&
    UIManager.setLayoutAnimationEnabledExperimental
  ) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  // Track expand/collapse events
  const handlePress = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

    // Track expand or collapse based on current state
    if (!expanded) {
      ReactGA.event({
        category: 'User Interaction',
        action: 'Expanded Article',
        label: article.title,
      });
    } else {
      ReactGA.event({
        category: 'User Interaction',
        action: 'Collapsed Article',
        label: article.title,
      });
    }

    setExpanded(!expanded);
  }, [expanded, article.title]);

  // Handle press on the source name
  const handleSourcePress = () => {
    const url = `source/${article.external_link_code}`;
    if (Platform.OS === 'web') {
      // Open in new tab on web with nofollow and security attributes
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      // Open in in-app WebView on mobile
      setWebviewVisible(true);
    }
  };

  // Set dynamic styles based on theme
  const backgroundColor = isDarkMode ? '#1e1e1e' : '#ffffff';
  const textColor = isDarkMode ? '#f5f5f5' : '#1e1e1e';
  const borderColor = isDarkMode ? '#333333' : '#dddddd';

  // Process the text to replace escaped newlines with actual newlines
  const processedText = article.text.replace(/\\n/g, '\n');

  // Split the text into paragraphs
  const paragraphs = processedText
    .split('\n\n')
    .map(para => para.trim())
    .filter(para => para.length > 0);

  // Format the publication date
  const formattedDate = formatDateTime(article.publication_datetime);

  return (
    <>
      <Pressable onPress={handlePress}>
        <View style={[styles.articleContainer, {backgroundColor, borderColor}]}>
          <Text style={[styles.title, {color: textColor}]}>
            {article.title}
          </Text>
          <View style={styles.metaContainer}>
            <Text style={[styles.metaInfo, {color: textColor}]}>
              {article.topics.map(topic => topic.label).join(', ')} •{' '}
              {Platform.OS === 'web' ? (
                <a
                  href={`source/${article.external_link_code}`}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  style={{textDecoration: 'underline', color: textColor}}>
                  {article.source.name}
                </a>
              ) : (
                <Text
                  style={[styles.sourceLink, {color: textColor}]}
                  onPress={handleSourcePress}>
                  {article.source.name}
                </Text>
              )}
            </Text>
            <Text style={[styles.date, {color: textColor}]}>
              {formattedDate}
            </Text>
          </View>
          <Text style={[styles.digest, {color: textColor}]}>
            {article.digest}
          </Text>
          {expanded && (
            <View style={styles.contentContainer}>
              {paragraphs.map((para, index) => (
                <Text
                  key={index}
                  style={[styles.paragraph, {color: textColor}]}>
                  {para}
                </Text>
              ))}
              <Text style={[styles.tags, {color: textColor}]}>
                {article.tags.map(tag => `#${tag.label}`).join(' ')}
              </Text>
              <Text style={[styles.disclaimer, {color: textColor}]}>
                This is a free interpretation of the original news, which may
                contain errors and inaccuracies. You can read the original{' '}
                {Platform.OS === 'web' ? (
                  <a
                    href={`source/${article.external_link_code}`}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    style={{textDecoration: 'underline', color: textColor}}>
                    here
                  </a>
                ) : (
                  <Text
                    style={[styles.sourceLink, {color: textColor}]}
                    onPress={handleSourcePress}>
                    here
                  </Text>
                )}
                .
              </Text>
            </View>
          )}
        </View>
      </Pressable>

      {/* Render WebView in a Modal on mobile platforms */}
      {Platform.OS !== 'web' && webviewVisible && (
        <Modal
          visible={webviewVisible}
          onRequestClose={() => setWebviewVisible(false)}
          animationType="slide">
          <View style={{flex: 1}}>
            <WebView
              source={{
                uri: `https://shortly.news/source/${article.external_link_code}`,
              }}
              style={{flex: 1}}
              startInLoadingState={true}
            />
            <Pressable
              onPress={() => setWebviewVisible(false)}
              style={styles.closeButton}>
              <Text style={styles.closeButtonText}>Close</Text>
            </Pressable>
          </View>
        </Modal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  articleContainer: {
    marginBottom: 16,
    padding: 16,
    borderRadius: 12,
    borderWidth: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 8,
  },
  metaContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  metaInfo: {
    fontSize: 14,
    flexShrink: 1,
  },
  sourceLink: {
    textDecorationLine: 'underline',
  },
  date: {
    fontSize: 14,
    marginLeft: 8,
  },
  digest: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 12,
  },
  contentContainer: {
    marginTop: 8,
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 26,
    marginBottom: 12,
    textAlign: 'justify',
  },
  tags: {
    fontSize: 14,
    marginTop: 12,
    fontStyle: 'italic',
  },
  disclaimer: {
    fontSize: 10,
    marginTop: 30,
    fontStyle: 'italic',
  },
  closeButton: {
    position: 'absolute',
    top: 40,
    right: 16,
    backgroundColor: 'rgba(0,0,0,0.6)',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 20,
  },
  closeButtonText: {
    color: '#ffffff',
    fontSize: 16,
  },
});

export default ArticleItem;
