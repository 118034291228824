// src/App.tsx
import React, {useEffect, useState, useMemo} from 'react';
import {
  SafeAreaView,
  StatusBar,
  useColorScheme,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import ReactGA from 'react-ga4';
import ArticleList from './components/ArticleList';
import TopicFilterMenu from './components/TopicFilterMenu';
import useArticles from './hooks/useArticles';

type Topic = {
  id: number;
  label: string;
};

const App = (): React.JSX.Element => {
  const [selectedTopics, setSelectedTopics] = useState<number[] | null>(null);
  const [allTopics, setAllTopics] = useState<Topic[] | null>(null);

  // Compute unselectedTopics only when selectedTopics and allTopics are available
  const unselectedTopics = useMemo(() => {
    if (selectedTopics === null || allTopics === null) {
      return null; // Return null until both are loaded
    }
    return allTopics
      .map(topic => topic.id)
      .filter(id => !selectedTopics.includes(id));
  }, [allTopics, selectedTopics]);

  const {articles, loading, isFetchingMore, fetchMoreArticles} =
    useArticles(unselectedTopics);

  const isDarkMode = useColorScheme() === 'dark';

  const backgroundStyle = {
    backgroundColor: isDarkMode ? '#1e1e1e' : '#f2f2f2',
  };

  useEffect(() => {
    // Initialize GA with your stream ID
    ReactGA.initialize('G-V7RECPVWQH');
    // Log the initial page view
    ReactGA.send('pageview');
  }, []);

  return (
    <SafeAreaView style={[styles.safeArea, backgroundStyle]}>
      {Platform.OS !== 'web' && (
        <StatusBar
          barStyle={isDarkMode ? 'light-content' : 'dark-content'}
          backgroundColor={backgroundStyle.backgroundColor}
        />
      )}
      <View style={styles.container}>
        <TopicFilterMenu
          selectedTopics={selectedTopics}
          setSelectedTopics={setSelectedTopics}
          setAllTopics={setAllTopics} // Pass setAllTopics to populate topics
        />
        {unselectedTopics !== null && (
          <ArticleList
            articles={articles}
            loading={loading}
            isFetchingMore={isFetchingMore}
            onEndReached={fetchMoreArticles}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: Platform.OS === 'web' ? 20 : 0,
    maxWidth: 800,
    alignSelf: 'center',
    width: '100%',
  },
});

export default App;
