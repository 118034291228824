import { parseISO, format, getYear, isToday, isThisWeek } from 'date-fns';

export function formatDateTime(dateString: string): string {
  const date = parseISO(dateString);
  const currentYear = getYear(new Date());
  const messageYear = getYear(date);

  if (isToday(date)) {
    return format(date, 'HH:mm'); // Only time in 24-hour format
  } else if (isThisWeek(date)) {
    return format(date, 'EEEE'); // Full day of the week
  } else if (messageYear === currentYear) {
    return format(date, 'd MMM'); // Date in "1 Dec" format
  } else {
    return format(date, 'dd.MM.yyyy'); // Date in "24.12.2022" format
  }
}