// src/components/TopicFilterMenu.tsx
import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Modal,
  ScrollView,
  Platform,
  Switch,
  useColorScheme,
  Pressable,
} from 'react-native';
import axios, {AxiosResponse} from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

const localStorageAvailable =
  typeof window !== 'undefined' && window.localStorage;

type Topic = {
  id: number;
  label: string;
};

type Props = {
  selectedTopics: number[] | null;
  setSelectedTopics: (topics: number[]) => void;
  setAllTopics: (topics: Topic[]) => void;
};

const TopicFilterMenu: React.FC<Props> = ({
  selectedTopics,
  setSelectedTopics,
  setAllTopics,
}) => {
  const isDarkMode = useColorScheme() === 'dark';
  const [topics, setTopics] = useState<Topic[]>([]); // State to store fetched topics
  const STORAGE_KEY = 'unselectedTopics'; // Store blacklist of unselected topics
  const [modalVisible, setModalVisible] = useState(false);
  const [tempUnselectedTopics, setTempUnselectedTopics] = useState<number[]>(
    [],
  ); // Temporary blacklist

  // Fetch topics and set selectedTopics and allTopics
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response: AxiosResponse<Topic[]> = await axios.get('api/topics/');
        const fetchedTopics = response.data;
        setTopics(fetchedTopics);

        // Load stored unselected topics
        let storedUnselected: string | null = null;
        if (Platform.OS === 'web' && localStorageAvailable) {
          storedUnselected = window.localStorage.getItem(STORAGE_KEY);
        } else {
          storedUnselected = await AsyncStorage.getItem(STORAGE_KEY);
        }

        let selectedTopicIds: number[];
        if (storedUnselected) {
          const parsedUnselected = JSON.parse(storedUnselected);
          selectedTopicIds = fetchedTopics
            .map(topic => topic.id)
            .filter(id => !parsedUnselected.includes(id));
        } else {
          // If no blacklist, select all topics by default
          selectedTopicIds = fetchedTopics.map(topic => topic.id);
        }

        // Set selectedTopics before updating allTopics
        setSelectedTopics(selectedTopicIds);

        // Now update allTopics in App component
        setAllTopics(fetchedTopics);
      } catch (error) {
        console.error(
          'Failed to fetch topics or load unselected topics:',
          error,
        );
      }
    };

    fetchTopics();
  }, [setAllTopics, setSelectedTopics]);

  // Save unselected topics (blacklist) whenever selectedTopics change
  useEffect(() => {
    const saveUnselectedTopics = async () => {
      try {
        const unselectedTopics = topics
          .map(topic => topic.id)
          .filter(id => !selectedTopics!.includes(id)); // Use non-null assertion
        const unselectedString = JSON.stringify(unselectedTopics);

        if (Platform.OS === 'web' && localStorageAvailable) {
          window.localStorage.setItem(STORAGE_KEY, unselectedString);
        } else {
          await AsyncStorage.setItem(STORAGE_KEY, unselectedString);
        }
      } catch (error) {
        console.error('Failed to save unselected topics:', error);
      }
    };

    if (topics.length > 0 && selectedTopics !== undefined) {
      saveUnselectedTopics();
    }
  }, [selectedTopics, topics]);

  // Toggle topic in the temporary unselected list (blacklist)
  const toggleTopic = (id: number) => {
    if (tempUnselectedTopics.includes(id)) {
      setTempUnselectedTopics(
        tempUnselectedTopics.filter(topicId => topicId !== id),
      );
    } else {
      setTempUnselectedTopics([...tempUnselectedTopics, id]);
    }
  };

  // Select/Deselect all topics
  const selectAllTopics = () => {
    if (tempUnselectedTopics.length === 0) {
      setTempUnselectedTopics(topics.map(topic => topic.id)); // All deselected
    } else {
      setTempUnselectedTopics([]); // All selected (no unselected topics)
    }
  };

  // Open the modal and initialize tempUnselectedTopics with current blacklist
  const openModal = () => {
    const currentUnselected = topics
      .map(topic => topic.id)
      .filter(id => !selectedTopics!.includes(id)); // Use non-null assertion
    setTempUnselectedTopics(currentUnselected);
    setModalVisible(true);
  };

  // Apply the selection and update the blacklist
  const applySelection = () => {
    const newSelectedTopics = topics
      .map(topic => topic.id)
      .filter(id => !tempUnselectedTopics.includes(id)); // Select only topics not in the blacklist
    setSelectedTopics(newSelectedTopics);
    setModalVisible(false);
  };

  // Dynamic styles for light/dark mode
  const backgroundColor = isDarkMode ? '#1e1e1e' : '#ffffff';
  const textColor = isDarkMode ? '#f5f5f5' : '#1e1e1e';
  const borderColor = isDarkMode ? '#333333' : '#dddddd';

  return (
    <>
      <View style={styles.filterButtonContainer}>
        <TouchableOpacity
          onPress={openModal}
          style={[
            styles.filterButton,
            {
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              borderWidth: 1,
            },
          ]}>
          <Text style={[styles.filterButtonText, {color: textColor}]}>
            Filter Topics
          </Text>
        </TouchableOpacity>
      </View>

      <Modal
        visible={modalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => setModalVisible(false)}>
        <Pressable
          style={[styles.modalBackdrop, {backgroundColor}]}
          onPress={() => setModalVisible(false)}>
          <Pressable style={[styles.modalContainer, {backgroundColor}]}>
            <Text style={[styles.modalTitle, {color: textColor}]}>
              Select Topics
            </Text>

            {/* "Select All" stays fixed */}
            <View style={[styles.topicItem, {borderBottomColor: borderColor}]}>
              <Text style={[styles.topicLabel, {color: textColor}]}>
                Select All
              </Text>
              <Switch
                value={tempUnselectedTopics.length === 0}
                onValueChange={selectAllTopics}
              />
            </View>

            {/* Scrollable container for topics */}
            <ScrollView style={styles.topicsList}>
              {topics.map(topic => (
                <View
                  key={topic.id}
                  style={[styles.topicItem, {borderBottomColor: borderColor}]}>
                  <Text style={[styles.topicLabel, {color: textColor}]}>
                    {topic.label}
                  </Text>
                  <Switch
                    value={!tempUnselectedTopics.includes(topic.id)}
                    onValueChange={() => toggleTopic(topic.id)}
                  />
                </View>
              ))}
            </ScrollView>

            {/* Buttons stay fixed */}
            <View style={styles.modalButtons}>
              <TouchableOpacity
                onPress={() => setModalVisible(false)}
                style={[
                  styles.modalButton,
                  {
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: 1,
                  },
                ]}>
                <Text style={[styles.modalButtonText, {color: textColor}]}>
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={applySelection}
                style={[
                  styles.modalButton,
                  {
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: 1,
                  },
                ]}>
                <Text style={[styles.modalButtonText, {color: textColor}]}>
                  Apply
                </Text>
              </TouchableOpacity>
            </View>
          </Pressable>
        </Pressable>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  filterButtonContainer: {
    paddingVertical: 8,
    paddingHorizontal: 0,
    alignItems: 'center',
  },
  filterButton: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 20,
  },
  filterButtonText: {
    fontSize: 16,
  },
  modalBackdrop: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    maxWidth: 800,
    width: '100%',
    paddingTop: 40,
    paddingHorizontal: 16,
    borderRadius: 12,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 16,
    textAlign: 'center',
  },
  topicsList: {
    maxHeight: 200, // Set height limit for the scrollable container
    marginVertical: 16,
  },
  topicItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    borderBottomWidth: 1,
  },
  topicLabel: {
    fontSize: 16,
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: 16,
  },
  modalButton: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 8,
  },
  modalButtonText: {
    fontSize: 16,
  },
});

export default TopicFilterMenu;
